import createPersistedState from 'vuex-persistedstate'
import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    accessedViaButton: false
  },
  mutations: {
    setUserData (state, value) {
      if (value == null) {
        state.user = null
      } else {
        const fullName = `${value.name} ${value.lastname}`
        state.user = {
          ...value,
          fullName
        }
      }
    },
    setAccessedViaButton (state, status) {
      state.accessedViaButton = status
    }
  },
  actions: {},
  getters: {},
  modules: {},
  plugins: [createPersistedState()]
})
