<template>
  <div class="sidenav__items">
    <!-- Dashboard -->
    <router-link
      :class="['sidenav__item', routeActive == 'Dashboard' ? 'sidenav__item--active' : '']"
      :to="{ name: 'Dashboard' }"
    >
      <img
        v-if="routeActive == 'Dashboard'"
        class="sidenav__ico"
        src="@/assets/images/icons/ico_dashboard--white.svg"
        alt="Icono Dashboard"
      />
      <img
        v-else
        class="sidenav__ico"
        src="@/assets/images/icons/ico_dashboard--black.svg"
        alt="Icono Dashboard"
      />
      <span class="sidenav__text">Mi Panel</span>
    </router-link>

    <content-loader
      viewBox="0 0 400 100"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      v-if="loading"
    >
      <rect x="6" y="3" rx="18" ry="18" width="70" height="70" />
      <rect x="80" y="2" rx="18" ry="18" width="271" height="70" />
    </content-loader>
    <template v-if="commercialPlans.includes(1)">
      <!-- Catálogo General -->
      <router-link
        :class="['sidenav__item', routeActive == 'general-catalog' ? 'sidenav__item--active' : '']"
        :to="{ name: 'GeneralCatalog' }"
      >
        <img
          v-if="routeActive == 'general-catalog'"
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-search--white.svg"
          alt="Icono Dashboard"
        />
        <img
          v-else
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-search--black.svg"
          alt="Icono Dashboard"
        />
        <span class="sidenav__text">Catálogo General</span>
      </router-link>
    </template>
    <template v-else>
      <div class="sidenav__item disable-plan">
        <img
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-search--black.svg"
          alt="Icono Dashboard"
        />
        <span class="sidenav__text">Catálogo General</span>
      </div>
    </template>

    <template v-if="commercialPlans.includes(2)">
      <!-- Ev proveedores propios -->
      <router-link
        :class="['sidenav__item', routeActive == 'own-suppliers' ? 'sidenav__item--active' : '']"
        :to="{ name: 'BuyerOwnSuppliersSearch' }"
      >
        <img
          v-if="routeActive == 'own-suppliers'"
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-own--white.svg"
          alt="Icono Dashboard"
        />
        <img
          v-else
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-own--black.svg"
          alt="Icono Dashboard"
        />
        <span class="sidenav__text">Evaluación Proveedores Propios</span>
      </router-link>
    </template>
    <template v-else>
      <div class="sidenav__item disable-plan">
        <img
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-own--black.svg"
          alt="Icono Dashboard"
        />
        <span class="sidenav__text">Evaluación Proveedores Propios</span>
      </div>
    </template>

    <template v-if="commercialPlans.includes(3)">
      <!-- Ev. Proveedores general -->
      <router-link
        :class="[
          'sidenav__item',
          routeActive == 'general-suppliers' ? 'sidenav__item--active' : ''
        ]"
        :to="{ name: 'MarketCatalog' }"
      >
        <img
          v-if="routeActive == 'general-suppliers'"
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-market--white.svg"
          alt="Icono Dashboard"
        />
        <img
          v-else
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-market--black.svg"
          alt="Icono Dashboard"
        />
        <span class="sidenav__text">Evaluación proveedores del mercado</span>
      </router-link>
    </template>
    <template v-else>
      <div class="sidenav__item disable-plan">
        <img
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-market--black.svg"
          alt="Icono Dashboard"
        />
        <span class="sidenav__text">Evaluación proveedores del mercado</span>
      </div>
    </template>
    <template v-if="commercialPlans.includes(4)">
      <!-- Ev. Proveedores general -->
      <router-link
        :class="['sidenav__item', routeActive == 'custom-catalog' ? 'sidenav__item--active' : '']"
        :to="{ name: 'CustomCatalog' }"
      >
        <img
          v-if="routeActive == 'custom-catalog'"
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-custom--white.svg"
          alt="Icono Dashboard"
        />
        <img
          v-else
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-custom--black.svg"
          alt="Icono Dashboard"
        />
        <span class="sidenav__text">Catálogo personalizado</span>
      </router-link>
    </template>
    <template v-else>
      <div class="sidenav__item disable-plan">
        <img
          class="sidenav__ico"
          src="@/assets/images/icons/ico_plan-custom--black.svg"
          alt="Icono Dashboard"
        />
        <span class="sidenav__text">Catálogo personalizado</span>
      </div>
    </template>

    <router-link
      :class="['sidenav__item', routeActive == 'GapReport' ? 'sidenav__item--active' : '']"
      :to="{ name: 'GapReport' }"
    >
      <img
        v-if="routeActive == 'GapReport'"
        class="sidenav__ico"
        src="@/assets/images/icons/ico_report--white.svg"
        alt="Icono Dashboard"
      />
      <img
        v-else
        class="sidenav__ico"
        src="@/assets/images/icons/ico_report--black.svg"
        alt="Icono Dashboard"
      />
      <span class="sidenav__text">Reportes</span>
    </router-link>

    <!-- Ajustes sistema comprador -->
    <router-link
      :class="['sidenav__item', routeActive == 'BuyerSettings' ? 'sidenav__item--active' : '']"
      :to="{ name: 'BuyerUsers' }"
    >
      <img
        v-if="routeActive == 'BuyerSettings'"
        class="sidenav__ico"
        src="@/assets/images/icons/ico_settings--white.svg"
        alt="Icono Dashboard"
      />
      <img
        v-else
        class="sidenav__ico"
        src="@/assets/images/icons/ico_settings--black.svg"
        alt="Icono Dashboard"
      />
      <span class="sidenav__text">Ajustes</span>
    </router-link>
  </div>

  <div class="sidenav__items sidenav__items--companies">
    <div class="sidenav__items sidenav__items--companies">
      <h6 class="sidenav__items-title persona-info__name">Perfil Empresa</h6>
      <router-link
        class="sidenav__item sidenav__item--no-bg"
        :to="{
          name: 'ClientBuyer',
          params: { clientId: 1 }
        }"
      >
        <div class="sidenav-item__logo-container">
          <div class="company-info__initials">
            <h6>
              {{ getInitials($store.state.user?.businessData?.BusinessName) }}
            </h6>
          </div>
        </div>
        <span class="persona-info__name">{{ $store.state.user?.businessData?.BusinessName }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';
export default {
  name: 'SidenavProviderComponent',
  components: {
    ContentLoader
  },
  props: {
    routeActive: {
      type: String
    }
  },
  data() {
    return {
      commercialPlans: [],
      loading: true,
      provider: null
    };
  },
  async created() {
    const planInfo = await this.$axios.post('buyer/get-billing-info');
    if (planInfo.status == 200) {
      this.commercialPlans = planInfo.data.commercialPlans;
      this.loading = false;
      // if (
      //   (this.$route.name == 'SuppliersSearch' && res.data != 1) ||
      //   (this.$route.name == 'BuyerOwnSuppliersSearch' && res.data != 2) ||
      //   (this.$route.name == 'BuyerGeneralSuppliers' && res.data != 3)
      // ) {
      //   this.$toast.error('Acceso denegado');
      //   this.$router.push({ name: 'Dashboard' });
      // }
    }
  },
  computed: {
    showPlans() {
      if (this.commercialPlanId === 1 || this.commercialPlanId === 3) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    getInitials(name) {
      const words = (name || '').split(' ');
      let initials = '';

      for (let i = 0; i < words.length; i++) {
        initials += words[i][0]?.toUpperCase() || '';
        if (initials.length >= 2) {
          break;
        }
      }

      return initials;
    }
  }
};
</script>

<style scoped>
.persona-info__name {
  max-width: 150px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.disable-plan {
  opacity: 30%;
  cursor: not-allowed;
}
</style>
