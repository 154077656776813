<template>
  <div class="sidenav__items">
    <!-- Dashboard -->
    <router-link
      :class="['sidenav__item', routeActive == 'Dashboard' ? 'sidenav__item--active' : '']"
      :to="{ name: 'Dashboard' }"
    >
      <img
        v-if="routeActive == 'Dashboard'"
        class="sidenav__ico"
        src="@/assets/images/icons/ico_dashboard--white.svg"
        alt="Icono Dashboard"
      />
      <img
        v-else
        class="sidenav__ico"
        src="@/assets/images/icons/ico_dashboard--black.svg"
        alt="Icono Dashboard"
      />
      <span class="sidenav__text">Mi Panel</span>
    </router-link>

    <!-- Formularios -->
    <router-link
      :class="['sidenav__item', routeActive == 'DimensionsForms' ? 'sidenav__item--active' : '']"
      :to="{ name: 'DimensionsForms' }"
    >
      <img
        v-if="routeActive == 'DimensionsForms'"
        class="sidenav__ico"
        src="@/assets/images/icons/ico_forms--white.svg"
        alt="Icono Dashboard"
      />
      <img
        v-else
        class="sidenav__ico"
        src="@/assets/images/icons/ico_forms--black.svg"
        alt="Icono Dashboard"
      />
      <span class="sidenav__text">Formularios</span>
    </router-link>

    <!-- Reporte de brechas: Proveedores -->
    <router-link
      :class="[
        'sidenav__item',
        routeActive == 'ProviderManagementFilesList' ? 'sidenav__item--active' : ''
      ]"
      :to="{ name: 'ProviderManagementFilesList' }"
    >
      <img
        v-if="routeActive == 'ProviderManagementFilesList'"
        class="sidenav__ico"
        src="@/assets/images/icons/ico_report--white.svg"
        alt="Icono Dashboard"
      />
      <img
        v-else
        class="sidenav__ico"
        src="@/assets/images/icons/ico-multipleFiles--black.svg"
        alt="Icono Dashboard"
      />
      <span class="sidenav__text">Documentos</span>
    </router-link>
    <router-link
      :class="['sidenav__item', routeActive == 'GapReport' ? 'sidenav__item--active' : '']"
      :to="{ name: 'GapReport' }"
    >
      <img
        v-if="routeActive == 'GapReport'"
        class="sidenav__ico"
        src="@/assets/images/icons/ico_report--white.svg"
        alt="Icono Dashboard"
      />
      <img
        v-else
        class="sidenav__ico"
        src="@/assets/images/icons/ico_report--black.svg"
        alt="Icono Dashboard"
      />
      <span class="sidenav__text">Reportes</span>
    </router-link>
  </div>

  <div class="sidenav__items sidenav__items--companies">
    <h6 class="sidenav__items-title persona-info__name">Perfil Empresa</h6>
    <router-link class="sidenav__item sidenav__item--no-bg" :to="{ name: 'ClientProfile' }">
      <div class="sidenav-item__logo-container">
        <div class="company-info__initials">
          <h6>
            {{ getInitials($store.state.user?.businessData?.BusinessName) }}
          </h6>
        </div>
      </div>
      <span class="persona-info__name">{{ $store.state.user?.businessData?.BusinessName }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SidenavProviderComponent',
  props: {
    routeActive: {
      type: String
    }
  },
  methods: {
    getInitials(name) {
      const words = (name || '').split(' ');
      let initials = '';

      for (let i = 0; i < words.length; i++) {
        initials += words[i][0]?.toUpperCase() || '';
        if (initials.length >= 2) {
          break;
        }
      }

      return initials;
    }
  },
  computed: {
    reeportsRoute() {
      if (this.$store.state.user.role_id == 1)
        return {
          name: 'GapReportInspect',
          params: { clientId: this.$route.params.clientId }
        };
      else
        return {
          name: 'GapReport'
        };
    }
  }
};
</script>
<style scoped>
.persona-info__name {
  max-width: 150px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
