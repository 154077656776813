import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/AuthView.vue'),
    meta: {
      title: 'Auth'
    },
    beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next)
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/DashboardView.vue'),
    meta: {
      section: 'Dashboard',
      title: 'Dashboard'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2, 3])
  },
  {
    path: '/applicants',
    name: 'Applicants',
    component: () => import('@/views/ApplicantsView.vue'),
    meta: {
      section: 'Applicants',
      title: 'Prospectos'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/applicants/new-enrollment/:id?',
    name: 'ApplicantsEnrollmentNew',
    component: () => import('@/views/ApplicantsEnrollmentNewView.vue'),
    meta: {
      section: 'Applicants',
      title: 'Nuevo enrolamiento'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('@/views/ClientsView.vue'),
    meta: {
      section: 'Clients',
      title: 'Clientes'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/clients/provider/:clientId',
    name: 'ClientProvider',
    component: () => import('@/views/ClientProviderProfileView.vue'),
    meta: {
      section: 'Clients',
      title: 'Perfil cliente'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2])
  },
  {
    path: '/clients/buyer/:clientId',
    name: 'ClientBuyer',
    component: () => import('@/views/ClientBuyerProfileView.vue'),
    meta: {
      section: 'Clients',
      title: 'Perfil cliente'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2, 3])
  },
  {
    path: '/profile',
    name: 'ClientProfile',
    component: () => import('@/views/ClientProviderProfileView.vue'),
    meta: {
      section: 'Clients',
      title: 'Perfil cliente'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1, 2])
  },
  {
    path: '/settings/groups-and-persons',
    name: 'GroupsAndPersons',
    component: () => import('@/views/SettingsGroupsAndPersonsView.vue'),
    meta: {
      section: 'Settings',
      sub: 'Groups-and-persons',
      title: 'Grupos y personas'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/settings/groups-and-persons/group/:groupId',
    name: 'Group',
    component: () => import('@/views/SettingsGroupView.vue'),
    meta: {
      section: 'Settings',
      sub: 'Groups-and-persons',
      title: 'Grupo'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/settings/duxiter-index',
    name: 'DuxiterIndex',
    component: () => import('@/views/SettingsDuxiterIndexView.vue'),
    meta: {
      section: 'Settings',
      sub: 'Duxiter-index',
      title: 'Índice Duxiter'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/settings/categories-segments-and-family',
    name: 'CategoriesSegmentsAndFamily',
    component: () => import('@/views/SettingsCategoriesSegmentsAndFamilyView.vue'),
    meta: {
      section: 'Settings',
      sub: 'CategoriesSegmentsAndFamily',
      title: 'Categorías, segmentos y familia'
    }
  },
  {
    path: '/settings/category/:categoryId',
    name: 'Category',
    component: () => import('@/views/SettingsCategoryView.vue'),
    meta: {
      section: 'Settings',
      sub: 'Category',
      title: 'Categoría'
    }
  },
  {
    path: '/settings/segment/:segmentId',
    name: 'Segment',
    component: () => import('@/views/SettingsSegmentView.vue'),
    meta: {
      section: 'Settings',
      sub: 'Segment',
      title: 'Segmento'
    }
  },
  {
    path: '/settings/industries-and-divisions',
    name: 'IndustriesAndDivisions',
    component: () => import('@/views/SettingsIndustriesAndDivisionsView.vue'),
    meta: {
      section: 'Settings',
      sub: 'IndustriesAndDivisions',
      title: 'Industrias y divisiones'
    }
  },
  {
    path: '/settings/data-annualization',
    name: 'AnnualizationData',
    component: () => import('@/views/SettingsAnnualizationDataView.vue'),
    meta: {
      section: 'Settings',
      sub: 'AnnualizationData',
      title: 'Actualizacion anual'
    }
  },
  {
    path: '/settings/industry/:industryId',
    name: 'Industry',
    component: () => import('@/views/SettingsIndustryView.vue'),
    meta: {
      section: 'Settings',
      sub: 'Industry',
      title: 'División'
    }
  },
  {
    path: '/enrollment/:token',
    name: 'EnrollmentGuest',
    component: () => import('@/views/EnrollmentGuestView.vue'),
    meta: {
      title: 'Enrolamiento',
      public: true
    },
    beforeEnter: (to, from, next) => allowAnonymous(to, from, next)
  },
  {
    path: '/enrollment-simple/:token',
    name: 'EnrollmentSimpleGuest',
    component: () => import('@/views/EnrollmentSimpleGuestView.vue'),
    meta: {
      title: 'Enrolamiento',
      public: true
    },
    beforeEnter: (to, from, next) => allowAnonymous(to, from, next)
  },

  {
    path: '/dimensions-forms',
    name: 'DimensionsForms',
    component: () => import('@/views/DimensionsFormsView.vue'),
    meta: {
      section: 'DimensionsForms',
      title: 'Formularios por dimensión'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [2])
  },
  {
    path: '/clients/:clientId/dimensions-forms',
    name: 'DimensionsFormsInspect',
    component: () => import('@/views/DimensionsFormsView.vue'),
    meta: {
      section: 'DimensionsForms',
      title: 'Formularios por dimensión'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },

  {
    path: '/dimension-form/:dimensionFormId',
    name: 'DimensionForm',
    component: () => import('@/views/DimensionFormView.vue'),
    meta: {
      section: 'DimensionsForms',
      title: 'Formulario por dimensión'
    }
  },
  {
    path: '/clients/:clientId/dimensions-forms/:dimensionFormId',
    name: 'DimensionFormInspect',
    component: () => import('@/views/DimensionFormView.vue'),
    meta: {
      section: 'DimensionsForms',
      title: 'Formulario por dimensión'
    }
  },

  {
    path: '/clients/:clientId/dimensions-forms/:dimensionFormId/provider-client/:providerClientId',
    name: 'DimensionFormMarketReputationClient',
    component: () => import('@/views/DimensionFormMarketReputationClient.vue'),
    meta: {
      section: 'DimensionsForms',
      title: 'Formulario por dimensión'
    }
  },
  {
    path: '/referencia-cliente/:token',
    name: 'MarketReputationGuest',
    component: () => import('@/views/DimensionFormMarketReputationGuestView.vue'),
    meta: {
      title: 'Reputación de mercado',
      public: true
    },
    beforeEnter: (to, from, next) => allowAnonymous(to, from, next)
  },
  {
    path: '/clients/new-buyer',
    name: 'BuyersRegistrationNew',
    component: () => import('@/views/BuyersRegistrationNewView.vue'),
    meta: {
      title: 'Nuevo comprador',
      section: 'Clients'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/clients/buyers',
    name: 'Buyers',
    meta: {
      title: 'Buyers List',
      section: 'Clients'
    },
    component: () => import('@/views/BuyersView.vue'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/clients/providers',
    name: 'Providers',
    component: () => import('@/views/ProvidersView.vue'),
    meta: {
      title: 'Providers List',
      section: 'Clients'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/clients/edit-buyer/:buyerId',
    name: 'BuyersRegistrationEdit',
    component: () => import('@/views/BuyersRegistrationEditView.vue'),
    meta: {
      title: 'Editar comprador',
      section: 'Clients'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },
  {
    path: '/gap-report',
    name: 'GapReport',
    props: true,
    component: () => import('@/views/GapReportView.vue'),
    meta: {
      section: 'GapReport',
      title: 'Reporte de brechas'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [2, 3])
  },
  {
    path: '/files-list-management',
    name: 'ProviderManagementFilesList',
    props: true,
    component: () => import('@/views/providersViews/ProviderManagementFilesView.vue'),
    meta: {
      section: 'FilesManagment',
      title: 'Gestión de documentos'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [2, 3])
  },
  {
    path: '/files-management/:buyerId',
    name: 'ProviderManagementFiles',
    props: true,
    component: () => import('@/views/providersViews/ProviderRequestsListFilesView.vue'),
    meta: {
      section: 'FilesManagment',
      title: 'Gestión de documentos'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [2, 3])
  },
  {
    path: '/gap-report/:clientRole/:clientId',
    name: 'GapReportInspect',
    component: () => import('@/views/GapReportView.vue'),
    meta: {
      section: 'GapReport',
      title: 'Reporte de brechas'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [1])
  },

  // ----Comprador----
  {
    path: '/buyer-settings',
    name: 'BuyerSettings',
    component: () => import('@/views/BuyerSettingsView.vue'),
    meta: {
      title: 'Ponderaciones propias',
      section: 'BuyerSettings',
      sub: 'Buyer-duxiter-index'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  {
    path: '/buyer-directives',
    name: 'BuyerDirectives',
    component: () => import('@/views/buyersViews/BuyerDirectivesView.vue'),
    meta: {
      title: 'Directivas',
      section: 'BuyerSettings',
      sub: 'Buyer-directive'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  {
    path: '/buyer-users',
    name: 'BuyerUsers',
    component: () => import('@/views/BuyerSettingsUsersView.vue'),
    meta: {
      title: 'Configuración Usuarios',
      section: 'BuyerSettings',
      sub: 'Buyer-users'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  {
    path: '/Buyer-invitation',
    name: 'BuyerInvitation',
    component: () => import('@/views/BuyerSettingsInvitationsView.vue'),
    meta: {
      title: 'Envío de invitaciones',
      section: 'BuyerSettings',
      sub: 'Buyer-invitation'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },

  {
    path: '/records/:clientId',
    name: 'BuyerProviderRecord',
    component: () => import('@/views/BuyerProviderRecord.vue'),
    meta: {
      title: 'Ficha de proveedor',
      section: 'own-suppliers'
    },
    beforeEnter: (to, from, next) => {
      if (store.state.accessedViaButton) {
        store.commit('setAccessedViaButton', false);
        next();
      } else {
        next('/general-suppliers');
      }
    }
  },
  {
    path: '/provider-management/:clientId/:tabId',
    name: 'BuyerProviderManagement',
    component: () => import('@/views/buyersViews/BuyerProviderManagementView.vue'),
    meta: {
      title: 'Ficha de proveedor',
      section: 'BuyerProviderManagement',
      sub: 'Buyer-provider-management'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  {
    path: '/record/:clientId',
    name: 'BuyerProviderRecordSimple',
    component: () => import('@/views/BuyerProviderRecordSimple.vue'),
    meta: {
      title: 'Ficha de proveedor',
      section: 'own-suppliers'
    },
    beforeEnter: (to, from, next) => {
      if (store.state.accessedViaButton) {
        store.commit('setAccessedViaButton', false);
        next();
      } else {
        next('/suppliers-search');
      }
    }
  },
  // BUSCADORES
  // 1.- BÚSQUEDA DE PROVEEDORES
  {
    path: '/suppliers-search',
    name: 'SuppliersSearch',
    component: () => import('@/views/BuyerProvidersSearchView.vue'),
    meta: {
      title: 'Búsqueda de Proveedores',
      section: 'suppliers-search'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  {
    path: '/general-catalog',
    name: 'GeneralCatalog',
    component: () => import('@/views/catalogs/GeneralCatalogView.vue'),
    meta: {
      title: 'Catálogo general',
      section: 'general-catalog'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  {
    path: '/custom-catalog',
    name: 'CustomCatalog',
    component: () => import('@/views/catalogs/CustomCatalogView.vue'),
    meta: {
      title: 'Catálogo personalizado',
      section: 'custom-catalog'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  // 2.- EVALUACIÓN DE PROVEEDORES PROPIOS
  {
    path: '/own-suppliers',
    name: 'BuyerOwnSuppliersSearch',
    component: () => import('@/views/BuyerOwnSuppliersSearchView.vue'),
    meta: {
      title: 'Evaluación de Proveedores Propios',
      section: 'own-suppliers'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  // 3.- Evaluación de proveedores general
  {
    path: '/market-suppliers',
    name: 'MarketCatalog',
    component: () => import('@/views/catalogs/MarketCatalogVIew.vue'),
    meta: {
      title: 'Evaluación de Proveedores del mercado',
      section: 'market-suppliers'
    },
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next, [3])
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/auth'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) document.title = `${to.meta.title} - Duxiter`;
  if (from.name) {
    to.meta.previousRoute = from.name;
  }
  if (to.query.logout) {
    store.commit('setUserData', null);
    return next({ name: 'Auth' });
  } else {
    // if(store.state?.user?.role_id == 2 && !store.state?.user?.setup_finished && to.name == 'Dashboard')
    //   return next({ name: 'DimensionsForms'})
    next();
  }
});

router.afterEach(() => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'instant'
  });
});

const checkAuthenticated = (to, from, next, id = []) => {
  if (store.state?.user?.role_id && id.includes(store.state.user.role_id)) {
    next();
  } else {
    next({ name: 'Auth' });
  }
};

const checkNotAuthenticated = (to, from, next) => {
  if (!store.state.user?.id) {
    next();
  } else {
    next({ name: 'Dashboard' });
  }
};

const allowAnonymous = (to, from, next) => {
  next();
};

export default router;
