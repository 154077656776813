import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import VueCookies from 'vue3-cookies'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import { vMaska } from 'maska'

const app = createApp(App)

// Configuración axios
axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL || window.location.origin}/api`
axios.defaults.headers.common.Accept = 'application/json; charset=utf-8'
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use((req) => {
  if (req.baseURL) {
    req.url = '/' + req.url
  }
  const authToken = app.config.globalProperties.$cookies?.get('jwt')
  if (authToken) {
    req.headers.Authorization = authToken
  }
  return req
})

app.provide('$axios', axios)
app.config.globalProperties.$axios = axios

app.provide('$cookies', VueCookies)
app.config.globalProperties.$cookies = VueCookies

app.config.globalProperties.$msalInstance = {}
app.use(VueCookies, { expireTimes: '15m' })

app.config.globalProperties.$toast = useToast()

app.directive('maska', vMaska)

router.beforeEach(async (to, from, next) => {
  const isPublicRoute = to.meta.public
  const authToken = app.config.globalProperties.$cookies?.get('jwt')

  if (!isPublicRoute && authToken) {
    try {
      axios.defaults.headers.common.Authorization = authToken
      await axios.get('init')
      next()
    } catch (error) {
      app.config.globalProperties.$cookies.remove('jwt')
      delete axios.defaults.headers.common.Authorization
      store.commit('setUserData', null)
      next('/auth')
    }
  } else {
    delete axios.defaults.headers.common.Authorization
    store.commit('setUserData', null)
    next()
  }
})

app.use(store).use(router).mount('#app')
