<template>
  <div
    :class="[
      'layout',
      $store.state.user ? '' : 'layout--auth',
      $route.path.includes('/enrollment/') ||
      $route.path.includes('/enrollment-simple/') ||
      $route.path.includes('referencia-cliente')
        ? 'layout--guest'
        : ''
    ]"
  >
    <!-- Sidenav -->
    <div v-if="$store.state.user" class="sidenav-wrapper">
      <SidenavComponent />
    </div>

    <!-- Content -->
    <div class="content-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
import SidenavComponent from '@/components/SidenavComponent.vue';

export default {
  name: 'App',
  components: {
    SidenavComponent
  },
  data() {
    return {};
  },
  created() {
    window.addEventListener('resize', this.getHeaderHight);
    this.getHeaderHight();
  },
  updated() {
    window.addEventListener('resize', this.getHeaderHight);
    this.getHeaderHight();
  },
  mounted() {
    window.addEventListener('resize', this.getHeaderHight);
    this.getHeaderHight();
  },
  methods: {
    getHeaderHight() {
      const header = document.querySelector('.section-header');
      const mainContent = document.querySelector('.main-content');
      const sideWrapper = document.querySelector('.side-wrapper');

      if (header) {
        const height = header.clientHeight + 14;

        if (mainContent) {
          mainContent.style.marginTop = height + 'px';
        }

        if (sideWrapper) {
          sideWrapper.style.marginTop = height + 'px';
        }
      }
    }
  }
};
</script>

<style lang="sass">
@use '@/assets/styles/main.sass'
</style>
